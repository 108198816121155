<template>
  <b-card>
    <validation-observer ref="updatePasswordForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        key="updatePasswordForm"
        @submit="submit"
        :form="$refs.updatePasswordForm"
        :disabled="invalid"
      >
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              :label="$t('field.oldPassword')"
              label-for="old-password"
            >
              <validation-provider
                vid="oldPassword"
                #default="{ errors }"
                :name="$t('field.oldPassword')"
                rules="required"
              >
                <n-password-input
                  v-model="data.oldPassword"
                  id="old-password"
                  name="old-password"
                  :placeholder="$t('field.oldPassword')"
                  :errors="errors"
                ></n-password-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="new-password"
              :label="$t('field.newPassword')"
            >
              <validation-provider
                #default="{ errors }"
                vid="newPassword"
                :name="$t('field.newPassword')"
                rules="required|min:6"
              >
                <n-password-input
                  v-model="data.newPassword"
                  id="new-password"
                  name="new-password"
                  :placeholder="$t('field.newPassword')"
                  :errors="errors"
                ></n-password-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="retype-new-password"
              :label="$t('field.retypeNewPassword')"
            >
              <validation-provider
                #default="{ errors }"
                vid="retypeNewPassword"
                :name="$t('field.retypeNewPassword')"
                rules="required|confirmed:newPassword"
              >
                <n-password-input
                  v-model="data.retypeNewPassword"
                  id="retype-new-password"
                  name="retype-new-password"
                  :placeholder="$t('field.retypeNewPassword')"
                  :errors="errors"
                ></n-password-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12" class="text-right">
            <n-button-loading
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-1 mr-1"
              :loading="loading"
              :disabled="invalid"
            >
              {{ $t("button.saveChanges") }}
            </n-button-loading>
            <n-button-loading
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              :loading="loading"
              class="mt-1"
              @submit="resetForm"
            >
              {{ $t("button.reset") }}
            </n-button-loading>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import NButtonLoading from "@/components/NButtonLoading";
import NPasswordInput from "@/components/NPasswordInput";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";

const ProfileRepository = Repository.get("profile");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    NButtonLoading,
    NPasswordInput,
    NFormConfirmation,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        oldPassword: "",
        newPassword: "",
        retypeNewPassword: "",
      },
      loading: false,
    };
  },
  methods: {
    resetForm() {
      this.data = {
        old_password: "",
        new_password: "",
        retype_new_password: "",
      };

      this.$nextTick(() => {
        this.$refs.updatePasswordForm.reset();
      });
    },
    submit() {
      this.$refs.updatePasswordForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          ProfileRepository.updatePassword(this.data)
            .then((response) => {})
            .catch((error) => {
              if (error.response?.data?.message) {
                this.$refs.updatePasswordForm.setErrors(
                  error.response?.data?.message
                );
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
