<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
    v-model="tabIndex"
  >
    <!-- general tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("updateProfile.title") }}</span>
      </template>

      <account-setting-profile v-if="tabIndex === 0" />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("changePassword.title") }}</span>
      </template>

      <account-setting-password v-if="tabIndex === 1" />
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from "bootstrap-vue";
import AccountSettingProfile from "./AccountSettingProfile.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingProfile,
    AccountSettingPassword,
  },
  data() {
    return {
      tabIndex: this.$route.query.index ? Number(this.$route.query.index) : 0,
      options: {},
    };
  },
  watch: {
    tabIndex(val) {
      this.$router.replace({
        query: {
          index: val,
        },
      });
    },
  },
  computed: {
    typeId: function () {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId;
      }
      let userData = JSON.parse(localStorage.getItem("userData"));
      return userData?.typeId;
    },
  },
  mounted() {},
};
</script>
