var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"updatePasswordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"updatePasswordForm",attrs:{"form":_vm.$refs.updatePasswordForm,"disabled":invalid},on:{"submit":_vm.submit}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('field.oldPassword'),"label-for":"old-password"}},[_c('validation-provider',{attrs:{"vid":"oldPassword","name":_vm.$t('field.oldPassword'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-password-input',{attrs:{"id":"old-password","name":"old-password","placeholder":_vm.$t('field.oldPassword'),"errors":errors},model:{value:(_vm.data.oldPassword),callback:function ($$v) {_vm.$set(_vm.data, "oldPassword", $$v)},expression:"data.oldPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"new-password","label":_vm.$t('field.newPassword')}},[_c('validation-provider',{attrs:{"vid":"newPassword","name":_vm.$t('field.newPassword'),"rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-password-input',{attrs:{"id":"new-password","name":"new-password","placeholder":_vm.$t('field.newPassword'),"errors":errors},model:{value:(_vm.data.newPassword),callback:function ($$v) {_vm.$set(_vm.data, "newPassword", $$v)},expression:"data.newPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"retype-new-password","label":_vm.$t('field.retypeNewPassword')}},[_c('validation-provider',{attrs:{"vid":"retypeNewPassword","name":_vm.$t('field.retypeNewPassword'),"rules":"required|confirmed:newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-password-input',{attrs:{"id":"retype-new-password","name":"retype-new-password","placeholder":_vm.$t('field.retypeNewPassword'),"errors":errors},model:{value:(_vm.data.retypeNewPassword),callback:function ($$v) {_vm.$set(_vm.data, "retypeNewPassword", $$v)},expression:"data.retypeNewPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"type":"submit","variant":"primary","loading":_vm.loading,"disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("button.saveChanges"))+" ")]),_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"reset","variant":"outline-secondary","loading":_vm.loading},on:{"submit":_vm.resetForm}},[_vm._v(" "+_vm._s(_vm.$t("button.reset"))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }